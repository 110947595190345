.slick-slide {
  height: auto;
  cursor: not-allowed Im !important;
}
.TransportSlider > div > .slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 520px;
  align-items: center;
}

@media screen and (min-width: 1150px) and (max-width: 1250px) {
  .TransportSlider > div > .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: visible;
    height: 400px;
    align-items: center;
  }
}

@media screen and (min-width: 1600px) {
  .TransportSlider > div > .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: visible;
    height: 65vh;
    align-items: center;
  }
}

@media screen and (min-width: 1800px) {
  .TransportSlider > div > .slick-track {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    overflow: visible;
    height: 70vh;
    align-items: center;
  }
}
